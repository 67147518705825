exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletters-newsletter-template-tsx": () => import("./../../../src/pages/newsletters/newsletterTemplate.tsx" /* webpackChunkName: "component---src-pages-newsletters-newsletter-template-tsx" */),
  "component---src-pages-newsletters-tsx": () => import("./../../../src/pages/newsletters.tsx" /* webpackChunkName: "component---src-pages-newsletters-tsx" */),
  "component---src-pages-our-community-tsx": () => import("./../../../src/pages/our-community.tsx" /* webpackChunkName: "component---src-pages-our-community-tsx" */),
  "component---src-pages-our-programs-tsx": () => import("./../../../src/pages/our-programs.tsx" /* webpackChunkName: "component---src-pages-our-programs-tsx" */),
  "component---src-pages-our-school-tsx": () => import("./../../../src/pages/our-school.tsx" /* webpackChunkName: "component---src-pages-our-school-tsx" */),
  "component---src-pages-parent-information-tsx": () => import("./../../../src/pages/parent-information.tsx" /* webpackChunkName: "component---src-pages-parent-information-tsx" */),
  "component---src-pages-school-planning-tsx": () => import("./../../../src/pages/school-planning.tsx" /* webpackChunkName: "component---src-pages-school-planning-tsx" */)
}

